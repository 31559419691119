@tailwind base;

body{
  -webkit-print-color-adjust: exact;
}

a {
  @apply text-indigo-600;
}

/* a:hover {
  @apply border-b-2 border-hred-300;
} */

@tailwind components;

@tailwind utilities;

.pdf {
  /* pt-[2.5cm] */
  @apply pl-[2.5cm] pr-[1.5cm] pt-0

}

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

aside div {
  @apply -m-4;
  @apply p-5;
}


.hello li:hover > a {
  @apply stroke-black text-black bg-gray-600
}

.hello li:last-child:hover > button {
  @apply bg-menublue-100
}
.hello li:last-child:hover button > * {
  @apply bg-menublue-100
}

.hello li:last-child:hover button svg {
  @apply stroke-gray-200
}

.innerMenu:hover {
  @apply bg-menublue-100
}

.innerMenu li:hover > * {
  @apply stroke-black text-black bg-zinc-500 
}



/* aside ul li button:hover svg {
  @apply stroke-[#A0AEBF];
} */

td {
  @apply  border border-black p-1 text-xs;
}
th{
  @apply  border border-black text-xs;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@print {  
  @page {
    @bottom-right {
      content: 'Page number: ' counter(page);
      font-size: small;
    }
    
    @bottom-left {
      content: 'System name: @Actproof';
      font-size: small;
    }
  }
}



.inputError{
  @apply invalid:bg-red-200 invalid:border-blue-200 invalid:ring-blue-500
}

.datepicker-border {
  @apply focus:ring-0 focus:border-2 focus:border-indigo-500;
}

.datepicker-border-error {
  @apply ring-0 border-2 border-red-300;
}

@page {
  margin: 0;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background: rgb(207, 227, 252);
}

.react-datepicker__day.react-datepicker__day--highlighted:hover{
  background: #F0F0F0;
}

.react-datepicker__day.react-datepicker__day--highlighted{
  background: none;
  color: blue;
}


